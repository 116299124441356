<template>
  <div>
    <b-card title="Gelir - Gider Tablosu">
      <gelir-gider />
    </b-card>
    <pre>
      {{ valuation }}
    </pre>
  </div>
</template>
<script>
import { BCard } from 'bootstrap-vue'
import GelirGider from '@/views/Dealers/Reports/components/Valuation/GelirGider.vue'

export default {
  name: 'Valuation',
  components: {
    BCard,
    GelirGider,
  },
  computed: {
    valuation() {
      return this.$store.getters['valuation/getValuation']
    },
  },
  created() {
    this.getStartupReports()
    this.getValuation()
  },
  methods: {
    getValuation() {
      this.$store.dispatch('valuation/valuation', this.$route.params.id)
    },
    getStartupReports() {
      this.$store.dispatch('dealerReportStartupReport/getReports', this.$route.params.id)
    },
  },
}
</script>
